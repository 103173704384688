module.exports={
  "Learn More" : "Learn More",
  "CLOSED CAPTION PREVIEW": "CLOSED CAPTION PREVIEW",
  "Sample Text": "Sample Text",
  "Ad": "Ad",
  "Skip Ad": "Skip Ad",
  "LIVE": "LIVE",
  "GO LIVE": "GO LIVE",
  "CC Options": "Caption options",
  "On": "On",
  "Off": "Off",
  "Discovery": "Discovery",
  "Facebook Unavailable": "Facebook Unavailable",
  "Facebook Success": "Check out this video on Facebook",
  "Twitter Unavailable": "Twitter Unavailable",
  "Twitter Success": "Check out this video on Twitter",
  "Post Title": "Post Successfully",
  "Account Configure": "Please set up your account on device",
  "Discover": "Discover",
  "Up next": "Up next",
  "Video Quality": "Video Quality",
  "Invest In Social Change": "Invest In Social Change",
  "Share": "Share",
  "Start at": "Start at",
  "Embed": "Embed",
  "Email": "Email",
  "Check out this video": "I found a video you might be interested in! Check it out: ",
  "To": "To",
  "Subject": "Subject",
  "Message": "Message",
  "name@email.com": "name@email.com",
  "Optional Message": "Optional Message",
  "Send": "Send",
  "RELOAD YOUR SCREEN OR TRY SELECTING A DIFFERENT VIDEO": "RELOAD YOUR SCREEN OR TRY SELECTING A DIFFERENT VIDEO",
  "THIS IS NOT WORKING! :(": "THIS IS NOT WORKING! :(",
  "NETWORK ERROR": "NETWORK ERROR",
  "Cannot Contact Server": "Cannot Contact Server",
  "SAS ERROR": "SAS ERROR",
  "Invalid Authorization Response": "Invalid Authorization Response",
  "SAS GEO ERROR": "SAS GEO ERROR",
  "This video is not authorized in your location": "This video is not authorized in your location",
  "SAS DOMAIN ERROR": "SAS DOMAIN ERROR",
  "This video is not authorized for your domain": "This video is not authorized for your domain",
  "VIDEO NOT AVAILABLE YET": "VIDEO NOT AVAILABLE YET",
  "This video will be available soon": "This video will be available soon",
  "VIDEO NO LONGER AVAILABLE": "VIDEO NO LONGER AVAILABLE",
  "This video is no longer available": "This video is no longer available",
  "SAS DEVICE ERROR": "SAS DEVICE ERROR",
  "This video is not authorized for playback on this device": "This video is not authorized for playback on this device",
  "SAS PROXY ERROR": "SAS PROXY ERROR",
  "An anonymous proxy was detected. Please disable the proxy and retry.": "An anonymous proxy was detected. Please disable the proxy and retry.",
  "CONCURRENT STREAMS NUMBER EXCEEDED": "CONCURRENT STREAMS NUMBER EXCEEDED",
  "You have exceeded the maximum number of concurrent streams": "You have exceeded the maximum number of concurrent streams",
  "DEVICE BINDING ERROR": "DEVICE BINDING ERROR",
  "Device binding failed": "Device binding failed",
  "DEVICE ID TOO LONG": "DEVICE ID TOO LONG",
  "Device ID is too long": "Device ID is too long",
  "INVALID PLAYER TOKEN": "INVALID PLAYER TOKEN",
  "Invalid Ooyala Player token": "Invalid Ooyala Player token",
  "DEVICE LIMIT REACHED": "DEVICE LIMIT REACHED",
  "Device limit has been reached": "Device limit has been reached",
  "LICENSE ERROR": "LICENSE ERROR",
  "General error acquiring license": "General error acquiring license",
  "DRM SERVER ERROR": "DRM SERVER ERROR",
  "DRM server error": "DRM server error",
  "INVALID ENTITLEMENTS ERROR": "INVALID ENTITLEMENTS ERROR",
  "User Entitlement Terminated - Stream No Longer Active for the User": "User Entitlement Terminated - Stream No Longer Active for the User",
  "INVALID HEARTBEAT": "INVALID HEARTBEAT",
  "Invalid heartbeat response": "Invalid heartbeat response",
  "INVALID CONTENT": "INVALID CONTENT",
  "INVALID METADATA": "INVALID METADATA",
  "Invalid Metadata": "Invalid Metadata",
  "PLAYBACK ERROR": "PLAYBACK ERROR",
  "Could not play the content": "Could not play the content",
  "PLAYBACK STREAM ERROR": "PLAYBACK STREAM ERROR",
  "This video isn't encoded for your device": "This video isn't encoded for your device",
  "PLAYBACK LIVESTREAM ERROR": "PLAYBACK LIVESTREAM ERROR",
  "Live stream is off air": "Live stream is off air",
  "PLAYBACK NETWORK ERROR": "PLAYBACK NETWORK ERROR",
  "Network connection temporarily lost": "Network connection temporarily lost",
  "UNPLAYABLE CONTENT ERROR": "UNPLAYABLE CONTENT ERROR",
  "This video is not playable on this player": "This video is not playable on this player",
  "INVALID EXTERNAL ID": "INVALID EXTERNAL ID",
  "Invalid External ID": "Invalid External ID",
  "EMPTY CHANNEL ERROR": "EMPTY CHANNEL ERROR",
  "This channel is empty": "This channel is empty",
  "EMPTY CHANNEL SET ERROR": "EMPTY CHANNEL SET ERROR",
  "This channel set is empty": "This channel set is empty",
  "CHANNEL CONTENT ERROR": "CHANNEL CONTENT ERROR",
  "This channel is not playable at this time": "This channel is not playable at this time",
  "SOMETHING NOT RIGHT! THERE SHOULD BE VIDEOS HERE.": "SOMETHING NOT RIGHT! THERE SHOULD BE VIDEOS HERE.",
  "(Try Clicking The Discover Button Again On Reload Your Page)": "(Try Clicking The Discover Button Again On Reload Your Page)",
  "Something happened while we were trying to play your video! Click replay or simply reload your page.": "Something happened while we were trying to play your video! Click replay or simply reload your page.",
  "Language": "Language",
  "Color Selection": "Color Selection",
  "Caption Opacity": "Caption Opacity",
  "Font Type": "Font Type",
  "Font Size": "Font Size",
  "Text Enhancements": "Text Enhancements",
  "Text color": "Text color",
  "Background color": "Background color",
  "Window color": "Window color",
  "Transparent": "Transparent",
  "White": "White",
  "Blue": "Blue",
  "Magenta": "Magenta",
  "Green": "Green",
  "Yellow": "Yellow",
  "Red": "Red",
  "Cyan": "Cyan",
  "Black": "Black",
  "Text opacity": "Text opacity",
  "Background opacity": "Background opacity",
  "Window opacity": "Window opacity",
  "Font size": "Font size",
  "Small": "Small",
  "Medium": "Medium",
  "Large": "Large",
  "Extra Large": "Extra Large",
  "Text enhancement": "Text enhancement",
  "Uniform": "Uniform",
  "Depressed": "Depressed",
  "Raised": "Raised",
  "Shadow": "Shadow"
}