module.exports={
  "Learn More" : "了解更多",
  "CLOSED CAPTION PREVIEW": "闭合字幕预览",
  "Sample Text": "示例文本",
  "Ad": "广告",
  "Skip Ad": "跳过广告",
  "LIVE": "直播",
  "GO LIVE": "进入直播",
  "CC Options": "抄送选项",
  "On": "在",
  "Off": "关闭",
  "Discover": "发现",
  "Up next": "下一个",
  "Video Quality": "视频质量",
  "Invest In Social Change": "投资于社会变迁",
  "Share": "股票",
  "Start at": "开始于",
  "Embed": "嵌",
  "Email": "电子邮件",
  "Check out this video": "我发现一个你可能会感兴趣的视频！ 来看看吧：",
  "To": "至",
  "Subject": "学科",
  "Message": "信息",
  "name@email.com": "名字@电子邮件.com",
  "Optional Message": "可选的消息",
  "Send": "派",
  "RELOAD YOUR SCREEN OR TRY SELECTING A DIFFERENT VIDEO": "重新加载画面或者尝试选择其他视频",
  "THIS IS NOT WORKING! :(": "该视频无法播放！:(",
  "NETWORK ERROR": "网络错误",
  "Cannot Contact Server": "无法连接到服务器",
  "SAS ERROR": "SAS 错误",
  "Invalid Authorization Response": "无效的授权响应",
  "SAS GEO ERROR": "SAS 地理位置错误",
  "This video is not authorized in your location": "此视频在您的区域没有授权。",
  "SAS DOMAIN ERROR": "SAS 域错误",
  "This video is not authorized for your domain": "此视频没有授权给您的域。",
  "VIDEO NOT AVAILABLE YET": "视频尚无法获取",
  "This video will be available soon": "此视频将尽快上线",
  "VIDEO NO LONGER AVAILABLE": "视频已不存在",
  "This video is no longer available": "此视频已不存在",
  "SAS DEVICE ERROR": "SAS 设备错误",
  "This video is not authorized for playback on this device": "此视频没有授权给此设备的回放。",
  "SAS PROXY ERROR": "SAS 代理错误",
  "An anonymous proxy was detected. Please disable the proxy and retry.": "检测到匿名代理。请禁用代理并重试。",
  "CONCURRENT STREAMS NUMBER EXCEEDED": "并发流数量超标",
  "You have exceeded the maximum number of concurrent streams": "已超出并发流数量的最大值",
  "DEVICE BINDING ERROR": "设备绑定错误",
  "Device binding failed": "设备绑定失败",
  "DEVICE ID TOO LONG": "设备 ID 过长",
  "Device ID is too long": "设备 ID 过长",
  "INVALID PLAYER TOKEN": "无效的播放器标记",
  "Invalid Ooyala Player token": "无效的 Ooyala 播放器标记",
  "DEVICE LIMIT REACHED": "设备达到极限",
  "Device limit has been reached": "设备已达到限制",
  "LICENSE ERROR": "许可证错误",
  "General error acquiring license": "获取许可证时出现一般错误",
  "DRM SERVER ERROR": "DRM 服务器错误",
  "DRM server error": "DRM 服务器错误",
  "INVALID ENTITLEMENTS ERROR": "无效的权限错误",
  "User Entitlement Terminated - Stream No Longer Active for the User": "用户的权限已终止 - 此用户的数据流不再活跃",
  "INVALID HEARTBEAT": "无效的心跳响应",
  "Invalid heartbeat response": "无效的心跳响应",
  "INVALID CONTENT": "无效的内容",
  "INVALID METADATA": "无效的数据元",
  "Invalid Metadata": "无效的数据元",
  "PLAYBACK ERROR": "回放错误",
  "Could not play the content": "无法播放此内容",
  "PLAYBACK STREAM ERROR": "回放流错误",
  "This video isn't encoded for your device": "此视频没有为您的设备编码",
  "PLAYBACK LIVESTREAM ERROR": "回放直播流错误",
  "Live stream is off air": "直播流已停播",
  "PLAYBACK NETWORK ERROR": "回放网络错误",
  "Network connection temporarily lost": "网络连接暂时中断",
  "UNPLAYABLE CONTENT ERROR": "无法播放的内容错误",
  "This video is not playable on this player": "此视频在该播放器上无法播放。",
  "INVALID EXTERNAL ID": "无效的外部 ID",
  "Invalid External ID": "无效的外部 ID",
  "EMPTY CHANNEL ERROR": "空频道错误",
  "This channel is empty": "此频道为空",
  "EMPTY CHANNEL SET ERROR": "空频道设置错误",
  "This channel set is empty": "此频道设置为空",
  "CHANNEL CONTENT ERROR": "频道内容错误",
  "This channel is not playable at this time": "此频道现在无法播放",
  "Something happened while we were trying to play your video! Click replay or simply reload your page.": "当我们尝试播放您的视频时出现错误！单击“重新播放”或重新载入页面。",
  "Language": "语言",
  "Color Selection": "颜色选择",
  "Caption Opacity": "字幕不透明度",
  "Font Type": "字体类型",
  "Font Size": "字体大小",
  "Text Enhancements": "文本增强",
  "Text color": "文字颜色",
  "Background color": "背景颜色",
  "Window color": "窗口颜色",
  "Transparent": "透明",
  "White": "白色",
  "Blue": "蓝色",
  "Magenta": "品红",
  "Green": "绿色",
  "Yellow": "黄色",
  "Red": "红",
  "Cyan": "青色",
  "Black": "黑色",
  "Text opacity": "文本不透明度",
  "Background opacity": "背景不透明度",
  "Window opacity": "窗口透明度",
  "Font size": "字体大小",
  "Small": "小",
  "Medium": "中",
  "Large": "大",
  "Extra Large": "很大",
  "Text enhancement": "文本增强",
  "Uniform": "制服",
  "Depressed": "压花",
  "Raised": "上调",
  "Shadow": "阴影"
}