module.exports={
  "Learn More" : "Más información",
  "CLOSED CAPTION PREVIEW": "VISTA PRELIMINAR DE SUBTÍTULOS",
  "Sample Text": "Texto de muestra",
  "Ad": "Anuncio",
  "Skip Ad": "Omitir anuncio",
  "LIVE": "EN VIVO",
  "GO LIVE": "TRANSMITIR EN VIVO",
  "CC Options": "Opciones de subtitulado",
  "On": "Sí",
  "Off": "No",
  "Discover": "Descubrir",
  "Up next": "Siguiente",
  "Video Quality": "Calidad de video",
  "Invest In Social Change": "Invertir en el cambio social",
  "Share": "Compartir",
  "Start at": "Comenzar en",
  "Embed": "Insertar",
  "Email": "Correo electrónico",
  "Check out this video": "¡Encontré un vídeo que te puede interesar! Échale un vistazo: ",
  "To": "Para",
  "Subject": "Asunto",
  "Message": "Mensaje",
  "name@email.com": "nombre@correoelectrónico.com",
  "Optional Message": "Mensaje opcional",
  "Send": "Enviar",
  "RELOAD YOUR SCREEN OR TRY SELECTING A DIFFERENT VIDEO": "ACTUALICE LA PANTALLA O SELECCIONE UN VIDEO DIFERENTE",
  "THIS IS NOT WORKING! :(": "ESTO NO FUNCIONA :(",
  "NETWORK ERROR": "SE PRODUJO UN ERROR EN LA RED",
  "Cannot Contact Server": "No se puede contactar al servidor",
  "SAS ERROR": "SE PRODUJO UN ERROR EN SAS",
  "Invalid Authorization Response": "La respuesta de autorización no es válida",
  "SAS GEO ERROR": "SE PRODUJO UN ERROR EN LA UBICACIÓN DE SAS",
  "This video is not authorized in your location": "Este video no está autorizado en su ubicación",
  "SAS DOMAIN ERROR": "SE PRODUJO UN ERROR EN EL DOMINIO DE SAS",
  "This video is not authorized for your domain": "Este video no está autorizado en su dominio",
  "VIDEO NOT AVAILABLE YET": "EL VIDEO TODAVÍA NO ESTÁ DISPONIBLE",
  "This video will be available soon": "El video estará disponible próximamente",
  "VIDEO NO LONGER AVAILABLE": "EL VIDEO YA NO ESTÁ DISPONIBLE",
  "This video is no longer available": "El video ya no está disponible",
  "SAS DEVICE ERROR": "SE PRODUJO UN ERROR EN EL DISPOSITIVO DE SAS",
  "This video is not authorized for playback on this device": "La reproducción de este video no está autorizada en este dispositivo",
  "SAS PROXY ERROR": "SE PRODUJO UN ERROR EN EL PROXY DE SAS",
  "An anonymous proxy was detected. Please disable the proxy and retry.": "Se detectó un proxy anónimo. Deshabilite el proxy e inténtelo nuevamente.",
  "CONCURRENT STREAMS NUMBER EXCEEDED": "SE SUPERÓ LA CANTIDAD DE EMISIONES SIMULTÁNEAS",
  "You have exceeded the maximum number of concurrent streams": "Superó la cantidad máxima de emisiones simultáneas",
  "DEVICE BINDING ERROR": "SE PRODUJO UN ERROR EN EL ENLACE DE LOS DISPOSITIVOS",
  "Device binding failed": "Se produjo un error en el enlace de dispositivos",
  "DEVICE ID TOO LONG": "LA ID DEL DISPOSITIVO ES DEMASIADO LARGA",
  "Device ID is too long": "La ID del dispositivo es demasiado larga",
  "INVALID PLAYER TOKEN": "EL TOKEN DEL REPRODUCTOR NO ES VÁLIDO",
  "Invalid Ooyala Player token": "El token del reproductor de Ooyala no es válido",
  "DEVICE LIMIT REACHED": "SE ALCANZÓ EL LÍMITE DEL DISPOSITIVO",
  "Device limit has been reached": "Se alcanzó el límite del dispositivo",
  "LICENSE ERROR": "SE PRODUJO UN ERROR EN LA LICENCIA",
  "General error acquiring license": "Se produjo un error general al adquirir la licencia",
  "DRM SERVER ERROR": "SE PRODUJO UN ERROR EN EL SERVIDOR DE DRM",
  "DRM server error": "Se produjo un error en el servidor de DRM",
  "INVALID ENTITLEMENTS ERROR": "SE PRODUJO UN ERROR CON ASIGNACIONES NO VÁLIDAS",
  "User Entitlement Terminated - Stream No Longer Active for the User": "Finalizó la asignación del usuario. La emisión ya no estará activa para el usuario",
  "INVALID HEARTBEAT": "LA PULSACIÓN NO ES VÁLIDA",
  "Invalid heartbeat response": "La respuesta de pulsación no es válida",
  "INVALID CONTENT": "EL CONTENIDO NO ES VÁLIDO",
  "INVALID METADATA": "LOS METADATOS NO SON VÁLIDOS",
  "Invalid Metadata": "Los metadatos no son válidos",
  "PLAYBACK ERROR": "SE PRODUJO UN ERROR EN LA REPRODUCCIÓN",
  "Could not play the content": "No se pudo reproducir el contenido",
  "PLAYBACK STREAM ERROR": "SE PRODUJO UN ERROR EN LA EMISIÓN DE LA REPRODUCCIÓN",
  "This video isn't encoded for your device": "Este video no está codificado para su dispositivo",
  "PLAYBACK LIVESTREAM ERROR": "SE PRODUJO UN ERROR EN LA EMISIÓN EN VIVO DE LA REPRODUCCIÓN",
  "Live stream is off air": "La emisión en vivo está desconectada",
  "PLAYBACK NETWORK ERROR": "SE PRODUJO UN ERROR EN LA RED DE LA REPRODUCCIÓN",
  "Network connection temporarily lost": "Se perdió temporalmente la conexión con la red",
  "UNPLAYABLE CONTENT ERROR": "SE PRODUJO UN ERROR CON EL CONTENIDO, NO SE PUEDE REPRODUCIR",
  "This video is not playable on this player": "Este video no se puede reproducir en este dispositivo",
  "INVALID EXTERNAL ID": "LA ID EXTERNA NO ES VÁLIDA",
  "Invalid External ID": "La id externa no es válida",
  "EMPTY CHANNEL ERROR": "SE PRODUJO UN ERROR, EL CANAL ESTÁ VACÍO",
  "This channel is empty": "Este canal está vacío",
  "EMPTY CHANNEL SET ERROR": "SE PRODUJO UN ERROR, EL CONJUNTO DE CANALES ESTÁ VACÍO",
  "This channel set is empty": "Este conjunto de canales está vacío",
  "CHANNEL CONTENT ERROR": "SE PRODUJO UN ERROR CON EL CONTENIDO DEL CANAL",
  "This channel is not playable at this time": "Este canal no se puede reproducir en este momento",
  "Something happened while we were trying to play your video! Click replay or simply reload your page.": "Se produjo un error cuando intentamos reproducir tu video. Selecciona la opción para repetir el video o vuelve a cargar la página.",
  "Language": "Idioma",
  "Color Selection": "Selección de color",
  "Caption Opacity": "Opacidad de los subtítulos",
  "Font Type": "Tipo de fuente",
  "Font Size": "Tamaño de fuente",
  "Text Enhancements": "Mejoras en el texto",
  "Text color": "Color de texto",
  "Background color": "Color de fondo",
  "Window color": "Color de la ventana",
  "Transparent": "Transparente",
  "White": "Blanco",
  "Blue": "Azul",
  "Magenta": "Magenta",
  "Green": "Verde",
  "Yellow": "Amarillo",
  "Red": "Rojo",
  "Cyan": "Cian",
  "Black": "Negro",
  "Text opacity": "Opacidad del texto",
  "Background opacity": "Opacidad del fondo",
  "Window opacity": "Opacidad ventana",
  "Font size": "Tamaño de fuente",
  "Small": "Pequeña",
  "Medium": "Medio",
  "Large": "Grande",
  "Extra Large": "Muy grande",
  "Text enhancement": "Mejora de texto",
  "Uniform": "Uniforme",
  "Depressed": "En relieve",
  "Raised": "Elevado",
  "Shadow": "Sombra"
}